<template>
    <div>
      <div style="display: flex; font-size: 18px; margin: 20px 0">
        <div style="margin-right: 20px;">
          <span style="font-weight: bolder; ">
          负责人：
          </span>
          {{leader.leaderUserName}}
        </div>
        <div>
          <span style="font-weight: bolder; ">
          电话：
          </span>
          {{leader.leaderPhone}}
        </div>
      </div>

      <div id="shuttle">

        <div class="l_shuttle">
          <div class="l_header">
            <div class="l_title">
              {{titles[0]}}
              ({{lMultipleSelection.length}}/{{list.length}})
            </div>
            <div class="l_search_box">
              <el-input size="medium" autocomplete="off" v-model="lSearch" style="border-radius: 100px" @input="leftSearch" prefix-icon="el-icon-search" placeholder="请输入查询关键字"/>
            </div>
          </div>

          <slot name="l_body">
            <div class="l_body">
              <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="lHandleSelectionChange">
                <el-table-column type="selection"></el-table-column>
                <!--                        <el-table-column prop="url" label="头像">-->
                <!--                            <template slot-scope="scope">-->
                <!--                                <el-avatar size="medium" :src="scope.row.headImg"></el-avatar>-->
                <!--                            </template>-->
                <!--                        </el-table-column>-->
                <el-table-column prop="userName" label="姓名"></el-table-column>
                <!--                        <el-table-column prop="sex" label="性别"></el-table-column>-->
                <el-table-column prop="phone" label="电话" show-overflow-tooltip> </el-table-column>
              </el-table>
            </div>
          </slot>
        </div>

        <div style="width: 100px;display: flex;flex-direction: column;align-items:center;justify-content: center;">
          <div style="margin: 10px 0">
            <el-button type="primary" icon="el-icon-arrow-right" circle @click="toRight"></el-button>
          </div>
          <div style="margin: 10px 0">
            <el-button type="primary" icon="el-icon-arrow-left" circle @click="toLeft"></el-button>
          </div>
        </div>

        <div class="r_shuttle">
          <div class="r_header">
            <div class="r_title">
              {{titles[1]}}
              ({{rMultipleSelection.length}}/{{selectList.length}})
            </div>
            <div class="r_search_box">
              <el-input size="medium" autocomplete="off" v-model="rSearch" style="border-radius: 100px" @input="rightSearch" prefix-icon="el-icon-search" placeholder="请输入查询关键字"/>
            </div>
          </div>

          <slot name="r_body">
            <div class="r_body">
              <el-table ref="multipleTable" :data="selectList" tooltip-effect="dark" style="width: 100%" @selection-change="rHandleSelectionChange">
                <el-table-column type="selection"></el-table-column>
                <!--                        <el-table-column prop="url" label="头像">-->
                <!--                            <template slot-scope="scope">-->
                <!--                                <el-avatar size="medium" :src="scope.row.headImg"></el-avatar>-->
                <!--                            </template>-->
                <!--                        </el-table-column>-->
                <el-table-column prop="userName" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="电话" show-overflow-tooltip> </el-table-column>
                <el-table-column align="center">
                  <template slot-scope="{ row }">
                    <div v-if="leader.leaderUserId === row.userId">
                      <el-tag type="danger" size="small">负责人</el-tag>
                    </div>
                    <div v-else>
                      <el-tooltip class="item" effect="dark" content="设为负责人" placement="right">
                        <el-button type="primary" icon="el-icon-finished" size="mini" @click="setLeader(row)" circle></el-button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </slot>
        </div>

      </div>

    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                lSearch: '',
                rSearch: '',
                initList: [],
                initSelectList: [],
                list: [],
                selectList: [],
                lMultipleSelection: [],
                rMultipleSelection: [],
                leader: {}
            }
        },
        model: {
            prop: 'selectData',
            event: 'change'
        },
        props: {
            titles: {
                type: Array,
                default: function () {
                    return ['选择成员', '已选成员']
                }
            },
            data: {
                type: Array,
                default: function () {
                    return []
                }
            },
            selectData: {
                type: Array,
                default: function () {
                    return []
                }
            },
            leaderInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        created(){
          this.init()
          // console.log(this.data)
        },
        mounted() {
        },
        watch: {
            // lSearch () {
            //     this.leftSearch()
            // },
            // rSearch () {
            //     this.rightSearch()
            // },
            data() {
              this.init()
            }
        },
        methods: {
          init() {
            this.leader = JSON.parse(JSON.stringify(this.leaderInfo))
            this.initList = JSON.parse(JSON.stringify(this.data))
            this.initSelectList = JSON.parse(JSON.stringify(this.selectData))
            this.initSelectList.forEach(m => {
              this.initList.forEach(i => {
                // console.log(m.userId === i.userId)
                // console.log(m.userId + "=============" + i.userId)
                if (m.userId === i.userId) {
                  this.initList.remove(i)
                  // this.initSelectList.push(m)
                  // console.log('===========this.initList.remove(i)============')
                  // console.log(JSON.parse(JSON.stringify(this.initList)))
                  // console.log('===========this.initSelectList.push(m)============')
                  // console.log(JSON.parse(JSON.stringify(this.initSelectList)))
                }
              })
            })
            this.list = JSON.parse(JSON.stringify(this.initList))
            this.selectList = JSON.parse(JSON.stringify(this.initSelectList))

            if (this.selectList && this.selectList.length > 0) {
              this.setLeader(this.selectList.getByKey('userId', this.leader.leaderUserId))
            }
          },
            toRight () {
                if (this.lMultipleSelection.length === 0) return
                this.lMultipleSelection.forEach(m => {
                    this.initList.forEach(i => {
                        if (m.userId === i.userId) {
                            // let index = this.initList.indexOf(i)
                            // this.initList.splice(index, 1)
                            m.extraType = '0'
                            this.initList.remove(i)
                            this.initSelectList.push(m)
                        }
                    })
                })
                this.list = JSON.parse(JSON.stringify(this.initList))
                this.selectList = JSON.parse(JSON.stringify(this.initSelectList))
                this.$emit('change', this.selectList)
                if (this.selectList) {
                  this.setLeader(this.selectList[0] ? this.selectList[0] : null)
                }
                this.leftSearch()
                this.rightSearch()
            },
            toLeft () {
                if (this.rMultipleSelection.length === 0) return
                this.rMultipleSelection.forEach(m => {
                    this.initSelectList.forEach(i => {
                        if (m.userId === i.userId) {
                            this.initSelectList.remove(i)
                            // let index = this.initSelectList.indexOf(i)
                            // this.initSelectList.splice(index, 1)
                            this.initList.push(m)
                        }
                    })
                })
                this.list = JSON.parse(JSON.stringify(this.initList))
                this.selectList = JSON.parse(JSON.stringify(this.initSelectList))
                this.$emit('change', this.selectList)
                if (this.selectList) {
                  this.setLeader(this.selectList[0] ? this.selectList[0] : null)
                }
                this.leftSearch()
                this.rightSearch()
            },
            lHandleSelectionChange (val) {
                this.lMultipleSelection = val
            },
            rHandleSelectionChange (val) {
                this.rMultipleSelection = val
            },
            leftSearch () {
                const list = JSON.parse(JSON.stringify(this.initList))
                if (this.lSearch) {
                    const searchList = []
                    list.forEach(l => {
                        if (l.userName.indexOf(this.lSearch) > -1) {
                            searchList.push(l)
                        }
                    })
                    this.list = searchList
                } else {
                    this.list = JSON.parse(JSON.stringify(this.initList))
                }
            },
            rightSearch () {
                const list = JSON.parse(JSON.stringify(this.initSelectList))
                if (this.rSearch) {
                    const searchList = []
                    list.forEach(l => {
                        if (l.userName.indexOf(this.rSearch) > -1) {
                            searchList.push(l)
                        }
                    })
                    this.selectList = searchList
                } else {
                    this.selectList = JSON.parse(JSON.stringify(this.initSelectList))
                }
            },
          setLeader(row) {
            if (row) {
              this.leader = {
                leaderUserId: row.userId,
                leaderUserName: row.userName,
                leaderPhone: row.phone
              }
              this.selectList.remove(row)
              this.selectList.unshift(row)
            } else {
              this.leader = {}
            }
            this.$emit('leaderChange', this.leader)
          }
        }
    }
</script>

<style lang="scss" scoped>
    #shuttle {
        display: flex;
        flex: 1;
        height: 500px;
        .l_shuttle {
            width: 400px;
            border: 1px solid #EBEEF5;
            border-radius: 4px;
            .l_header {
                padding: 8px 10px;
                background-color: #F5F7FA;
                .l_title {
                    padding-bottom: 5px;
                    font-size: 16px;
                    color: #3e3e3e;
                }

                .l_search_box {
                    padding: 8px 0;
                }
            }
            .l_body {
                height: 403px;
                overflow: auto;
                //padding: 10px;
            }
        }


        .r_shuttle {
            width: 400px;
            border: 1px solid #EBEEF5;
            border-radius: 4px;
            .r_header {
                padding: 8px 10px;
                background-color: #F5F7FA;
                .r_title {
                    padding-bottom: 5px;
                    font-size: 16px;
                    color: #3e3e3e;
                }

                .r_search_box {
                    padding: 8px 0;
                }
            }
            .r_body {
                height: 403px;
                overflow: auto;
                //padding: 10px;
            }
        }

    }

</style>
