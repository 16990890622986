<template>
  <div>
    <!--  头部搜索  -->
    <div style="display: flex; justify-content: space-between">
      <div>
        <el-input
          size="small"
          v-model="searchForm.groupName"
          @keyup.enter.native="search"
          placeholder="请输入调度组名称"
        ></el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <el-button size="small" type="primary" @click="add" v-btn-permission="'008006009001'">新增</el-button>
      </div>
    </div>

    <div>
      <!--  table    -->

      <TableModel
        :is-show-select="true"
        :height="$store.state.app.client.height - 160"
        :loading="loading"
        :is-fixed="true"
        :table-data="surveyGroupList"
        table-size="medium"
      >
        <el-table-column label="操作" width="200">
          <template slot-scope="{ row }">
            <el-button v-btn-permission="'008006009002'" size="mini" type="text" @click="edit(row)"
              >编辑</el-button
            >
            <el-button
            v-btn-permission="'008006009004'"
              size="mini"
              v-if="row.status == 1"
              style="color: #f56c6c"
              type="text"
              @click="updateSurveyGroupStatus(row, '0')"
              >停用</el-button
            >
            <el-button
            v-btn-permission="'008006009003'"
              size="mini"
              v-if="row.status == 0"
              type="text"
              @click="updateSurveyGroupStatus(row, '1')"
              >启用</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="组名" prop="groupName"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <el-tag v-if="row.status == 1" type="success" effect="dark"
              >启用</el-tag
            >
            <el-tag v-if="row.status == 0" type="danger" effect="dark"
              >停用</el-tag
            >
            <!--            <span>{{row.status}}</span>-->
          </template>
        </el-table-column>
        <el-table-column label="负责人" prop="leaderUserName">
          <template slot-scope="{ row }">
            <span>{{ `${row.leaderUserName}  ${row.leaderPhone}` }}</span>
          </template>
        </el-table-column>
        <el-table-column label="组员" prop="usersName">
          <template slot-scope="{ row }" v-if="row.surveyUser">
            <!-- <span>{{ row.usersName }}</span> -->
            <el-popover
              placement="top-start"
              v-for="(item,index) in row.surveyUser"
              :key="index"
              title=""
              width="200"
              trigger="hover"
              style="margin-right:6px;"
              :content="item.phone">
              <el-button size="mini" slot="reference">{{item.userName}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </TableModel>
      <div style="display: flex; justify-content: right; padding-top: 10px">
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="searchForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchForm.pageSize"
          :total="searchForm.totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>

    <div>
      <el-dialog
        title="流调组信息"
        :visible.sync="dialogSurveyGroup"
        @close="surveyGroupInfo = { relationList: [] }"
        :close-on-click-modal="false"
      >
        <div>
          <div style="display: flex; align-items: center">
            <span style="width: 60px"><i style="color: red">*</i> 组名：</span>
            <el-input
              size="small"
              v-model="surveyGroupInfo.groupName"
              placeholder="请输入调度组名称"
            ></el-input>
          </div>
          <div v-if="dialogSurveyGroup">
            <shuttle-box
              :data="doctorList"
              v-model="surveyGroupInfo.relationList"
              :leaderInfo="surveyGroupInfo"
              @leaderChange="leaderChange"
              style="margin-top: 20px"
            ></shuttle-box>
          </div>
          <!--          <el-select v-model="surveyGroupInfo.leaderUserId" @change="leaderChange" placeholder="请选择负责人">-->
          <!--            <el-option-->
          <!--                v-for="(spl, i) in surveyGroupInfo.relationList"-->
          <!--                :key="spl.userId"-->
          <!--                :label="spl.userName"-->
          <!--                :value="spl.userId">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSurveyGroup = false">取 消</el-button>
          <el-button type="primary" @click="saveSurveyGroup()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TableModel from "@/components/TableModel";
import shuttleBox from "@/components/shuttleBox/index.vue";
import { getUserListByPeopleTypeApi } from "@/api/SystemManagement/index";
import { messageBox } from "../../utils/utils";
import {
  getSurveyGroupInfo,
  getSurveyGroupList,
  saveSurveyGroup,
  updateSurveyGroup,
  updateSurveyGroupStatus,
} from "@/api/SystemManagement/isolationpointmanagement";
export default {
  name: "surveyGroup",
  data() {
    return {
      loading: false,
      dialogSurveyGroup: false,
      surveyGroupFlag: "add",
      searchForm: {
        groupName: "",
        pageNumber: 1,
        pageSize: 20,
        totalPage: 0,
      },
      doctorList: [],
      surveyGroupList: [],
      relations: [],
      surveyGroupInfo: {
        groupName: "",
        relationList: [],
      },
    };
  },
  components: { TableModel, shuttleBox },
  mounted() {
    this.getSurveyGroupList();
    this.getUserListByPeopleType();
  },
  methods: {
    search() {
      this.getSurveyGroupList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNumber = 1;
      this.getSurveyGroupList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNumber = val;
      this.getSurveyGroupList();
    },
    add() {
      this.surveyGroupFlag = "add";
      this.dialogSurveyGroup = true;
    },
    edit(row) {
      getSurveyGroupInfo({ params: { id: row.id } }).then((res) => {
        this.surveyGroupFlag = "edit";
        this.dialogSurveyGroup = true;
        this.surveyGroupInfo = res.data.data;
      });
    },
    leaderChange(leader) {
      this.surveyGroupInfo.leaderUserId = leader.leaderUserId;
      this.surveyGroupInfo.leaderUserName = leader.leaderUserName;
      this.surveyGroupInfo.leaderPhone = leader.leaderUserName;
    },
    getSurveyGroupList() {
      this.loading = true;
      getSurveyGroupList({ params: this.searchForm }).then((res) => {
        this.surveyGroupList = res.data.data;
        this.loading = false;
        // this.dataForm.totalPage = res.data.totalRow
      });
    },
    saveSurveyGroup() {
      if (this.surveyGroupInfo.groupName === "") {
        this.$message.error("请输入组名称");
        return;
      }
      if (this.surveyGroupFlag === "edit") {
        updateSurveyGroup(this.surveyGroupInfo).then((res) => {
          if (res.data.code === "200") {
            this.dialogSurveyGroup = false;
            this.getSurveyGroupList();
          }
        });
      } else if (this.surveyGroupFlag === "add") {
        saveSurveyGroup(this.surveyGroupInfo).then((res) => {
          if (res.data.code === "200") {
            this.dialogSurveyGroup = false;
            this.getSurveyGroupList();
          }
        });
      }
    },
    updateSurveyGroupStatus(row, status) {
      console.log(1111111111);

      console.log(22222222222);
      messageBox(
        () => {
          updateSurveyGroupStatus({
            id: row.id,
            status: status,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
            }
            this.getSurveyGroupList();
          });
        },
        "warning",
        `是否修改${row.groupName}状态？`
      );
    },
    getUserListByPeopleType() {
      getUserListByPeopleTypeApi({
        params: {
          peopleType: "4",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.doctorList = [];
          res.data.data.forEach((item) => {
            this.doctorList.push({
              userId: item.id,
              userName: item.name,
              phone: item.linkPhone,
            });
          });
        }
        this.$forceUpdate();
      });
    },
    
  },
};
</script>

<style scoped>
</style>